import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { List } from '../const/types'

interface ListState {
  list: List[]
}

const initialState = { list: [] } satisfies ListState as ListState

const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    refreshList(state, action: PayloadAction<List[]>) {
      console.log('action.payload', action.payload)
      state.list = action.payload
    },
  },
})

export const { refreshList } = listSlice.actions
export default listSlice.reducer
