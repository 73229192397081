import { useMemo } from 'react'
import { List } from '../const/types'
import { Status } from '../const'

export default function useStatus(list: List[]) {
  const status = useMemo(() => {
    if (!list?.length || list[0]?.type === '下班') return Status.BEGIN
    if (list[0].type === '上班' && !list[0].rest_start) return Status.WORKING
    if (!!list[0].rest_start && !list[0].rest_end) return Status.RESTING
    if (!!list[0].rest_start && !!list[0].rest_end) return Status.AFTER_REST
  }, [list])

  return status
}
