import { useState, useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { API_URL } from '../const'
import axios from '../lib/axios'
import { refreshList } from '../lib/listSlice'

export default function useSearch(pass: string) {
  const toast = useToast({ position: 'top' })
  const dispatch = useDispatch()
  const [name, setName] = useState<string>('')

  const search = useCallback(async () => {
    try {
      toast.closeAll()
      const res = await axios.post(API_URL + '/api/punch/history',
        JSON.stringify({
          pass: pass,
        })
      );
      setName(res.data[0])
      dispatch(refreshList(res.data[1]))
      if (!Array.isArray(res.data)) throw Error

      toast({
        title: '查詢成功',
        status: 'success',
        isClosable: true,
      })
    } catch (err: any) {
      console.log(err);
      toast({
        title: '查詢失敗',
        status: 'error',
        isClosable: true,
      })
    }
  }, [pass, toast, dispatch, setName])

  return { search, name }
}
