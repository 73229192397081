import React, { useState, useEffect } from 'react';
import { ChakraProvider, Heading, Spacer, Flex, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr, Alert, AlertIcon } from '@chakra-ui/react';
import { VStack, SimpleGrid, Box, Text } from '@chakra-ui/react';
import { Center } from '@chakra-ui/react';
import { CloseIcon, ArrowUpIcon, ArrowDownIcon, ArrowBackIcon, SunIcon, MoonIcon, StarIcon, BellIcon, TimeIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react';
import './App.css';
import { useSelector } from 'react-redux'
import { RootState } from './lib/store'
import useStatus from './hooks/useStatus'
import useSearch from './hooks/useSearch'
import usePunch from './hooks/usePunch'
import { shiftTypes, Status } from './const'

function App() {
  const list = useSelector((state: RootState) => state.list.list)
  const status = useStatus(list)
  const [id, setId] = useState('')
  const [shift, setShift] = useState(-1)
  const [isLoading, setIsLoading] = useState(false)
  const { search, name } = useSearch(id)
  const { punch } = usePunch(id, shift)
  useEffect(() => {
    if (status === Status.WORKING || status === Status.RESTING || status === Status.AFTER_REST) {
      setShift(shiftTypes.indexOf(list[0].shift))
    } else if (status === Status.BEGIN) {
      setShift(-1)
    }
  }, [status, list, setShift])
  const handlePunch = async (punchType: number) => {
    setIsLoading(true)
    await punch(punchType)
    setIsLoading(false)
  }

  return (
    <ChakraProvider>

      <Alert status='info'>
        <AlertIcon />
        依照勞基法每 4 小時需休息 30 分鐘，因客流過大無法休息需告知現場主管並由主管回報，其餘未依照規定者比照約定休息時間辦法，<br/>早班至多以 4 小時計，晚班至多以 6.5 小時計，全班至多以 6 小時計，吧廚班至多以 6 小時計。
      </Alert>
      <Flex p='2'>
        <Box>
          <Heading size='md'>Mei mei 打卡</Heading>
        </Box>
        <Spacer />
        {!list?.length && !name ? <></> : 
        <Button colorScheme='grey' fontSize={'28px'} height='48px' width='100px' variant='outline' onClick={() => window.location.reload()}>
          <CloseIcon boxSize={5} />
            登出
        </Button>}
      </Flex>
      <VStack>
        <Center>
          <Text fontSize='5xl'>{name || '輸入員工編號'}</Text>
        </Center>
        {!!name ? <></> :
        <Center h='20' w='280px' p='2'>
          <Text fontSize='3xl'>{id}</Text>
        </Center>}

      {!!list?.length || !!name ? <></> : 
        <>
          <SimpleGrid columns={3} spacing={4}>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '1')}>
              1
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '2')}>
              2
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '3')}>
              3
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '4')}>
              4
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '5')}>
              5
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '6')}>
              6
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '7')}>
              7
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '8')}>
              8
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '9')}>
              9
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId('')}>
              清空
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '0')}>
              0
            </Button>
            <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => search()}>
              確認
            </Button>
          </SimpleGrid>
        </>}
      </VStack>
      {!list?.length && !name ? <></> : 
      <>
        {shift !== -1 || status !== Status.BEGIN ? <></> :
        <VStack p={4}>
          <SimpleGrid columns={4} spacing={4} pt='4'>
            <Button disabled={true} colorScheme='teal' fontSize={'28px'} height='200px' width='200px' variant='outline' onClick={() => setShift(0)}>
              <SunIcon boxSize={8} />
              早班
            </Button>
            <Button colorScheme='blue' fontSize={'28px'} height='200px' width='200px' variant='outline' onClick={() => setShift(1)}>
              <MoonIcon boxSize={8} />
              夜班
            </Button>
            <Button colorScheme='yellow' fontSize={'28px'} height='200px' width='200px' variant='outline' onClick={() => setShift(2)}>
              <StarIcon boxSize={8} />
              全班
            </Button>
            <Button colorScheme='purple' fontSize={'28px'} height='200px' width='200px' variant='outline' onClick={() => setShift(3)}>
              <BellIcon boxSize={8} />
              吧/廚
            </Button>
          </SimpleGrid>
        </VStack>}
        {shift === -1 ? <></> :
        <VStack>
          {status === Status.BEGIN &&
          <Button colorScheme='grey' fontSize={'14px'} width='80px' variant='outline' p='1' onClick={() => setShift(-1)}>
            <ArrowBackIcon boxSize={4} mr='1' />
            回班別
          </Button>}
          <VStack p={4}>
            <SimpleGrid columns={2} spacing={4} pt='4'>
              {status === Status.WORKING || status === Status.RESTING || status === Status.AFTER_REST ? 
              <Button isLoading={isLoading} isDisabled={status === Status.AFTER_REST} colorScheme={status === Status.RESTING ? 'purple' : 'yellow'} fontSize={'28px'} height='200px' width='200px' variant='outline' onClick={() => handlePunch(2)}>
                <TimeIcon boxSize={8} />
                休息
              </Button> :
              <Button isLoading={isLoading} colorScheme='teal' fontSize={'28px'} height='200px' width='200px' variant='outline' onClick={() => handlePunch(0)}>
                <ArrowUpIcon boxSize={8} />
                上班
              </Button>}
              <Button isLoading={isLoading} isDisabled={status === Status.BEGIN || status === Status.WORKING || status === Status.RESTING} colorScheme='pink' fontSize={'28px'} height='200px' width='200px' variant='outline' onClick={() => handlePunch(1)}>
                <ArrowDownIcon boxSize={8} />
                下班
              </Button>
            </SimpleGrid>
          </VStack>
        </VStack>
        }
        <Center>
          <TableContainer>
            <Table variant='striped'>
              <TableCaption>近期打卡</TableCaption>
              <Thead>
                <Tr>
                  <Th>班別</Th>
                  <Th>上下班</Th>
                  <Th>打卡時間</Th>
                  <Th>遲到時間</Th>
                  <Th>休息上</Th>
                  <Th>休息下</Th>
                  <Th>累計時間</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                list.map((listValue: any, index: number) => {
                  return (
                    <Tr key={index}>
                      <Td>{ listValue.shift }</Td>
                      <Td>{ listValue.type }</Td>
                      <Td>{ listValue.clock_time }</Td>
                      <Td>{ listValue.late ? new Date(listValue.late).toISOString().substring(11, 19) : ''  }</Td>
                      <Td>{ listValue.rest_start?.substring(11, 16) }</Td>
                      <Td>{ listValue.rest_end?.substring(11, 16) }</Td>
                      <Td>{ !listValue.sum || new Date(listValue.sum).toISOString().substring(11, 13) + 'h ' + new Date(listValue.sum).toISOString().substring(14, 16) + 'm' }</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Center>
      </>}
    </ChakraProvider>
  );
}

export default App;
