import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { API_URL } from '../const'
import axios from '../lib/axios'
import { refreshList } from '../lib/listSlice'

export default function usePunch(pass: string, shift: number) {
  const toast = useToast({ position: 'top' })
  const dispatch = useDispatch()

  const punch = useCallback(async (punchId: number) => {
    const punchType = ['in', 'out', 'rest'];
  
    try {
      toast.closeAll()
      const res = await axios.post(API_URL + '/api/punch/' + punchType[punchId],
        JSON.stringify({
          pass: pass,
          shift: shift,
          punchId: punchId,
        }))
      dispatch(refreshList(res.data))
      if (!Array.isArray(res.data)) throw Error

      toast({
        title: '打卡成功',
        status: 'success',
        isClosable: true,
      })
    } catch (err: any) {
      console.log(err);
      toast({
        title: '打卡失敗',
        status: 'error',
        isClosable: true,
      })
    }
  }, [pass, shift, toast, dispatch])

  return { punch }
}
